<template>
    <div class="wrap">
        <Payment :spaceId="spaceId" v-if="ShowPayment" @closePayment="ShowPayment = false" />
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <div class="space_pc" style="padding-bottom: 100px">
            <section class="main payment">
                <div class="main_box">
                    <h1>이용권 현황</h1>
                    <div class="payment_content">
                        <table class="payment_table">
                            <colgroup>
                                <col width="35%" />
                                <col width="10%" />
                                <col width="20%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th style="text-align: left">스페이스명</th>
                                    <th style="text-align: center">상품명</th>
                                    <th style="text-align: center">기간</th>
                                    <th style="text-align: center">다음 결제일</th>
                                    <th style="text-align: center">구독여부</th>
                                    <th style="text-align: center">결제 내역</th>
                                </tr>
                            </thead>
                            <tbody v-if="paymentList.length > 0">
                                <template v-for="(item, index) in paymentList">
                                    <tr :key="index">
                                        <td style="text-align: left" v-html="item.spaceNm" />
                                        <td style="text-align: center" v-if="item.spaceType !== 'Car'">매장용</td>
                                        <td style="text-align: center" v-else>차량용</td>
                                        <td style="text-align: center" v-if="item.spacePayAt === 'Y'">
                                            {{ dateTime(item.beginDate) }} ~
                                            {{ dateTime(item.expireDate) }}
                                        </td>
                                        <td style="text-align: center" v-else>-</td>
                                        <td style="text-align: center" v-if="item.spacePayAt === 'Y'">
                                            {{ dateTime(item.expireDate) }}
                                        </td>
                                        <td style="text-align: center" v-else>-</td>
                                        <td style="text-align: center" v-if="item.spacePayAt === 'Y'">구독중</td>
                                        <td style="text-align: center" v-else>미구독</td>
                                        <td style="text-align: center">
                                            <p style="margin-bottom: 0px" @click="showPayedList(item)">보기</p>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="6" style="padding: 0px">
                                        <div class="empty_area" v-if="loadingComplete">
                                            <img src="/media/img/empty_area.png" alt="" />
                                            <p style="margin-bottom: 0xp; border: 0px; background: #151515; color: rgba(255, 255, 255, 0.6)">
                                                이용권 내역이 없습니다.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <infinite-loading @infinite="infiniteHandler" spinner="waveDots">
                            <div slot="no-more"></div>
                            <div slot="no-results"></div>
                            <div slot="error"></div>
                        </infinite-loading>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <header class="header mo_header">
                <div class="header_box">
                    <div class="logo">
                        <a @click="linkPlayer()">
                            <img src="/media/images/icon_back_24.svg" />
                            <h2 style="margin-bottom: 0px">이용권 현황</h2>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="alarm_box">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="mobileAlarm"
                            >
                                <path
                                    d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.00024 18.666H20.3786"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <ellipse
                                    v-if="$store.state.noticeCount !== 0"
                                    cx="17.6756"
                                    cy="6.44444"
                                    rx="4.32431"
                                    ry="4.44444"
                                    fill="#FF1150"
                                />
                            </svg>
                        </div>
                        <div class="toggle" @click="showAside">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path
                                        d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </header>

            <section class="main payment">
                <div class="main_box">
                    <div class="payment_title">
                        <h1>이용권 현황</h1>
                    </div>
                    <div class="payment_content" v-if="paymentList.length > 0">
                        <template v-for="(item, index) in paymentList">
                            <ul :key="index" style="margin-bottom: 0px">
                                <li>
                                    <ul class="pay_info" style="padding-left: 0px">
                                        <li
                                            v-if="item.spacePayAt === 'Y'"
                                            style="color: #fd4e6d; background: rgba(253, 78, 109, 0.16); padding: 0 8px; border-radius: 50px"
                                        >
                                            구독중
                                        </li>
                                        <li
                                            v-else
                                            style="color: #fff; background: rgba(255, 255, 255, 0.16); padding: 0 8px; border-radius: 50px"
                                        >
                                            미구독
                                        </li>
                                    </ul>
                                </li>
                                <li style="display: flex; justify-content: space-between">
                                    <p style="margin-bottom: 0px; color: white" v-html="item.spaceNm" />
                                    <p @click="showPayedList(item)"><img src="/media/img/icon_arrow_small_24px.svg" /></p>
                                </li>
                                <li>
                                    <ul class="pay_info" style="padding-left: 0px">
                                        <li>상품명</li>
                                        <li v-if="item.spaceType !== 'Car'">매장용</li>
                                        <li v-else>차량용</li>
                                    </ul>
                                </li>
                                <li>
                                    <ul class="pay_info" style="padding-left: 0px">
                                        <li>구독 만료일</li>
                                        <li v-if="item.spacePayAt === 'Y'">{{ dateTime(item.expireDate) }}</li>
                                        <li style="margin-right: 5%" v-else>-</li>
                                    </ul>
                                </li>
                                <li>
                                    <ul class="pay_info" style="padding-left: 0px">
                                        <li>다음 결제일</li>
                                        <li v-if="item.spacePayAt === 'Y'">{{ dateTime(item.expireDate) }}</li>
                                        <li style="margin-right: 5%" v-else>-</li>
                                    </ul>
                                </li>
                            </ul>
                        </template>
                    </div>
                    <div class="payment_content" v-else>
                        <div class="empty_area" v-if="loadingComplete">
                            <img src="/media/img/empty_payment.png" alt="" />
                            <p>이용권 내역이 없습니다.</p>
                        </div>
                    </div>
                </div>
                <infinite-loading @infinite="infiniteHandler" spinner="waveDots">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                    <div slot="error"></div>
                </infinite-loading>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Payment from '@/components/modal/Payment/Payment.vue';
import InfiniteLoading from 'vue-infinite-loading';
import Aside from '@/layout/aside/Aside.vue';
import dayjs from 'dayjs';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';

export default defineComponent({
  components: {
    Aside,
    Payment,
    InfiniteLoading
  },

  data () {
    return {
      ShowPayment: false,
      paymentList: [],
      spaceId: '',
      asideStatus: false,
      loadingComplete: false,
      limit: 0
    };
  },

  methods: {
    linkPlayer () {
      var spaceId = VueCookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'Main' });
      }
    },

    infiniteHandler ($state) {
      this.loadingComplete = false;
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var limit = this.limit;

      axios
        .post('/api/profile/getPaymentList', { userId, limit }, { headers })
        .then(res => {
          if (res.data.result.length === 0) {
            $state.complete();
            this.loadingComplete = true;
          } else {
            setTimeout(() => {
              this.paymentList = this.paymentList.concat(res.data.result);
              this.limit += 10;
              $state.loaded();
            }, 1000);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    clickPre () {
      history.back();
    },

    showPayedList (space) {
      this.spaceId = space.spaceId;
      this.ShowPayment = true;
    },
    /*
    async getMySpaceList () {
      var userId = VueCookies.get("userId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/profile/getPaymentList", { userId }, { headers })
        .then((res) => {
          console.log(res.data);
          this.paymentList = res.data.result;
          this.total = res.data.result.length;
        })
        .catch((err) => {
          console.log(err);
        });

      this.pagingMethod(this.page);
    },
    */
    dateTime (value) {
      return dayjs(value).format('YY.MM.DD');
    },

    dateTime2 (value) {
      return dayjs(value).format('DD');
    },

    showAside () {
      this.asideStatus = true;
    },

    mobileAlarm () {
      router.push({ name: 'Notice0' });
    },

    player () {
      router.push('/player');
    }
  }
});
</script>
<style scoped>
.modal {
  display: block;
}

.empty_area {
  border-top: none;
}

.payment_content > ul > li:first-child .payment_history {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 12px;
  padding: 4px 8px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 100;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
